import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavDisplayCard from '../../components/NavDisplayCard/NavDisplayCard'
import packagesContext from '../../context/packagesContext';
import HotelCard from '../../components/Card/HotelCard';

const Hotels = () => {
  const context = useContext(packagesContext);
  
  const [hotels, setHotels] = useState(context?.busPackages);

  useEffect(() => {
    // setFilteredDetails(context);
    context.setTopLoadingBarProgress(0);
    // setHotels(context?.hotels);
    setHotels(
        context?.hotels?.sort((a, b) => {
            const orderA = a.order
            const orderB = b.order 
            if (orderA < orderB) {
              return -1;
            }
            if (orderA > orderB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })
    )
    context.setTopLoadingBarProgress(100);
  }, []);


  return (
    <>
        <Helmet>
        <html lang="en" />
        <title>
        The Best Hotels Near Hampi | HampiTrip | Ganga Tours and Travels
        </title>
        <meta
          name="description"
          content= "Explore the best hotels near Hampi. Enjoy convenient and comfortable hotel options to your dream destinations. Book your hotel rooms today."
        />
      </Helmet>
      <NavDisplayCard navStatus={"Hotels"} />
      <div className="container">
        <div className="row">
          {hotels?.map((element) => {
            return (
              <div
                className={`col-md-3 mb-4 `}
                key={element?.hotelTitle}
              >
                <HotelCard
                  title={element.hotelTitle}
                  hotelUrl={element.hotelUrl}
                  imageUrl={element.imageUrl}
                  priceStartsFrom={element.priceStartsFrom}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  )
}

export default Hotels