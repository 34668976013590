import React, { useEffect, useState } from "react";
import PackagesContext from "./packagesContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";

const PackagesState = (props) => {
  const [tourPackages, setTourPackages] = useState([]);
  const tourPackageRef = collection(db, "car-packages");

  const [tourPolicy, setTourPolicy] = useState([]);
  const tourPolicyRef = collection(db, "tour-policy");

  const [badgeDetails, setBadgeDetails] = useState([]);
  const badgeDetailsRef = collection(db, "badges-details-footer");

  const [quickSearchBadges, setQuickSearchBadges] = useState([]);
  const quickSearchBadgesRef = collection(db, "quick-search-badges");

  const [busPackages, setBusPackages] = useState([]);
  const busPackageRef = collection(db, "bus-packages");

  const [carousel, setCarousel] = useState([]);
  const carouselRef = collection(db, "home-page-carousel");

  const [announcement, setAnnouncement] = useState([]);
  const announcementRef = collection(db, "announcement-home-page");

  const [hotels, setHotels] = useState([]);
  const hotelsRef = collection(db, "hotels");

  const [topLoadingBarProgress, setTopLoadingBarProgress] = useState(0);

  useEffect(() => {
    setTopLoadingBarProgress(0);

    const getTourPackages = async () => {
      const data = await getDocs(tourPackageRef);
      setTourPackages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getTourPolicy = async () => {
      const data = await getDocs(tourPolicyRef);
      setTourPolicy(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getBadgeDetails = async () => {
      const data = await getDocs(badgeDetailsRef);
      setBadgeDetails(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getQuickSearchBadges = async () => {
      const data = await getDocs(quickSearchBadgesRef);
      setQuickSearchBadges(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    const getBusPackages = async () => {
      const data = await getDocs(busPackageRef);
      setBusPackages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const getCarousel = async () => {
      const data = await getDocs(carouselRef);
      setCarousel(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const getAnnouncement = async () => {
      const data = await getDocs(announcementRef);
      setAnnouncement(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const getHotels = async () => {
      const data = await getDocs(hotelsRef);
      setHotels(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getTourPolicy();
    getTourPackages();
    getBadgeDetails();
    getQuickSearchBadges();
    getCarousel();
    getAnnouncement();
    getBusPackages();
    getHotels();

    // eslint-disable-next-line
    setTopLoadingBarProgress(100);
  }, [db]);
  return (
    <PackagesContext.Provider
      value={{
        tourPackages,
        tourPolicy,
        badgeDetails,
        quickSearchBadges,
        busPackages,
        carousel,
        announcement,
        topLoadingBarProgress,
        hotels,
        setTopLoadingBarProgress,
      }}
    >
      {props.children}
    </PackagesContext.Provider>
  );
};
export default PackagesState;
