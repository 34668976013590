import React from "react";
import { Link } from "react-router-dom";

const HotelCard = (props) => {
  const { title, imageUrl, priceStartsFrom, hotelUrl } = props;
  return (
      <a target="_blank" href={hotelUrl} className="text-decoration-none">
        <div className="card grow " style={{ minHeight: "350px" }}>
          <img
            src={imageUrl}
            className="card-img-top"
            alt={title}
            height="180px"
            style={{ objectFit: "cover" }}
          />
          <div className="card-body blue-gradient-bg d-flex align-items-center">
            <h4 className="mx-auto card-title">{title}</h4>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">Starting from &#8377;{priceStartsFrom}</li>
            <li className="list-group-item">Book Now</li>
          </ul>
        </div>
      </a>
  
  );
};

export default HotelCard;
